import styled from "styled-components";

export const ContentWrapper = styled.div`
  background-color: black;
  display: flex;
  flex-flow: column;
  color: white;
  width: 100%;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-flow: row wrap;
  }
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 0 3rem 0;
  max-width: 580px;
  width: 100%;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 2rem;
    align-items: center;
  }
`;

export const Heading = styled.h2`
  font-size: 2.5rem;
  line-height: 2.8rem;
  text-align: center;
  margin: 0;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 2rem;
    line-height: 2.2rem;
    text-align: center;
    margin: 0;
  }
`;

export const SubHeading = styled.p`
  font-size: 1.1rem;
  text-align: center;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    text-align: center;
  }
`;

export const ImageBlock = styled.div`
  width: 800px;
  max-height: 80vh;
  margin: 0 0 3rem 0;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 100%;
    background-position: 50%;
  }
  @media (min-width: 992px) {
    max-height: 100vh;
  }
  @media (max-width: ${(props) => props.theme.screen.lg}) {
    max-height: 100vh;
  }
`;

export const MicroFeatureListWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 3rem 0;
  max-width: 650px;
  width: 100%;
  align-items: center;
`;

export const MicroFeatureListItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0.3rem;
  width: 160px;
`;

export const MicroFeatureListTitle = styled.h5`
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
`;

export const MicroFeatureListImageWrapper = styled.img`
  max-width: 90px;
`;

export const PreneurImg = styled.img`
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 992px) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 1170px) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
