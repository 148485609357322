import styled from "styled-components";
import {
  Section,
  Container,
} from "../../site-pages/MarketingPage/styles/global";
import FeatureBlock from "./FeatureBlock";
import {
  featureItemsData,
  featureHeroData,
} from "../../site-pages/MarketingPage/content/landingPageData";

const { heading, subHeading } = featureHeroData;

const Features = () => (
  <Section id="features">
    <HeadingGroup>
      <Heading>{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
    </HeadingGroup>
    {featureItemsData.map((data) => (
      <FeatureBlock key={data.position} {...data} />
    ))}
  </Section>
);

export default Features;

const HeadingGroup = styled(Container)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const Heading = styled.h1`
  font-size: 2.8rem;
  font-weight: 900;
  line-height: 3.5rem;
  text-align: center;
  color: black;

  margin: 0;
  max-width: 650px;
`;

const SubHeading = styled.p`
  text-align: center;
  color: black;
  max-width: 650px;
`;
