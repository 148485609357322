import styled from "styled-components";
import PlayCircle from "@material-ui/icons/PlayCircleOutline";

export const CtaSectionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: rgb(5, 0, 9);
  margin: 0 auto;
  padding: 6rem 0 3rem;
  text-transform: capitalize;
  text-align: center;
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  aspect-ratio: 2.665;
  margin-bottom: 2rem;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 70%;
    bottom: 0;
    left: 0;
    right: 0;

    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
`;

export const StyledIconPlay = styled(PlayCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem !important;
  fill: white !important;
  opacity: 0.45;
`;

export const StyledImage = styled.img`
  display: flex;
  height: auto;
  width: 100%;
  aspect-ratio: 2.665;
`;

export default { CtaSectionWrapper };
