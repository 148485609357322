import styled from "styled-components";
import { Container } from "../../../site-pages/MarketingPage/styles/global";

export const ContentContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  min-height: 400px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 600px;
  align-self: center;
  margin: 4rem 0 2rem 0;
`;

export const Heading = styled.h1`
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 0;
`;

export const SubHeading = styled.p`
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-flow: column;
  }
`;

export const ImageBlock = styled.div`
  max-width: 750px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const VideoBlock = styled.div`
  padding: 1rem 0 0 0;

  video {
    max-width: 355px;
    border-radius: 1rem;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    align-self: center;

    video {
      max-width: 435px;
      margin: 0 0 0 1rem;
    }
  }
`;
