import styled from "styled-components";

const Container = styled.div`
  background: rgb(5, 0, 9);
  background: linear-gradient(
    135deg,
    rgba(5, 0, 9, 1) 45%,
    rgba(255, 39, 162, 1) 350%
  );
  min-height: ${(props) => props.minHeight || 0}px;
  width: 100%;
  padding: 2rem 0;
`;

export default Container;
