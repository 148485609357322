export const COMPETITORS = [
  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666288/sCallWeb/WebAssets/compIcons/LinkTree_k6lker.png",
    company: "Linktree",
    descriptions: "Link in Bio",
    price: "$24/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666289/sCallWeb/WebAssets/compIcons/Squarespace_vp1fh1.png",
    company: "Square space",
    descriptions: "Landing Page + E-commerce",
    price: "$27/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666288/sCallWeb/WebAssets/compIcons/MailChimp_omkvsi.png",
    company: "Mailchimp",
    descriptions: "Email Marketing",
    price: "$29/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666287/sCallWeb/WebAssets/compIcons/Community_szgsfy.png",
    company: "Community",
    descriptions: "SMS Marketing",
    price: "$99/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666287/sCallWeb/WebAssets/compIcons/Calendly_djnre0.png",
    company: "Calendly",
    descriptions: "Book appointments",
    price: "$10/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666287/sCallWeb/WebAssets/compIcons/DotCards_mgtip1.png",
    company: "Dot Cards",
    descriptions: "Digital Business card",
    price: "$20",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666287/sCallWeb/WebAssets/compIcons/Bit.ly_wmcshk.png",
    company: "Bit.ly",
    descriptions: "Shorten links",
    price: "$29/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666288/sCallWeb/WebAssets/compIcons/qr-code-generator_cjktsz.png",
    company: "QR-code-generator",
    descriptions: "Dynamic QR Codes",
    price: "$16/mo",
  },

  {
    imageUrl:
      "https://res.cloudinary.com/scall/image/upload/v1694666289/sCallWeb/WebAssets/compIcons/Zapier_m8swnz.png",
    company: "Zapier",
    descriptions: "Sales Automation",
    price: "$10/mo",
  },
];

export const PRENEUR_FEATURES = [
  "Optimized Web Profile",
  "Mobile-First Design",
  "Seamless Product Sales",
  "Comprehensive Contact Management",
  "Event & Ticketing Solutions",
  "Advanced Scheduling Tools",
  "Efficient Invoicing",
  "Integrated SMS & Email Campaigns",
  "Dynamic QR Code Generation",
  "Digital Networking Card",
  "In-depth Analytics",
  "Intelligent AI Support",
  "Exclusive Preneur Community",
  "Integrated Money Tools",
];
