import styled, { css } from "styled-components";
import { grey } from "@material-ui/core/colors";
import { SwiperSlide } from "swiper/react";

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
`;

export const GalleryWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  margin: 1em 0;
  outline: none;
  width: 100%;
  .swiper-container {
    margin-bottom: 0.5em;
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30%;
      height: auto;
      margin: 0;
      padding: 1em;
      color: ${grey[200]};
      outline: none;
    }
    .swiper-button-prev {
      right: auto;
      left: 0;
      justify-content: flex-start;
    }
    .swiper-button-next {
      right: 0;
      left: auto;
      justify-content: flex-end;
    }
  }
  .swiper-slide {
    opacity: 0.4;
  }
  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-thumb-active {
    opacity: 1;
  }
`;

export const SlideWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background-color: ${grey[400]};
  border-radius: 0;
  min-width: 200px;
  min-height: 100vh;
  ${(props) =>
    props.thumb &&
    css`
      min-width: 5rem;
      min-height: 4rem;
      transition: opacity 0.3s ease;
    `}
  ${(props) =>
    props.bgImage &&
    css`
      background-image:background: hsla(0, 60%, 98%, 1);
      background: linear-gradient(90deg, hsla(0, 60%, 98%, 1) 0%, hsla(0, 0%, 5%, 0.4) 0%), url('${props.bgImage}');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `}
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding: 2rem 0 0 0;
    // height: 600px;
    background-size: cover;
    background-position: -510px 0;
  }
`;

export const ContentContainer = styled.div`
  padding: 3rem;
  margin-bottom: auto;
  margin-top: auto;
`;

export const TitleWrapper = styled.div``;
export const Title = styled.h2`
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;
  color: #ff871e;
  span {
    font-family: ganache;
    text-transform: lowercase;
    font-size: 2.7rem;
    font-weight: 400;
    @media (min-width: ${(props) => props.theme.screen.md}) {
      font-size: 5.5rem;
    }
  }
  @media (min-width: ${(props) => props.theme.screen.md}) {
    font-size: 3.5rem;
  }
`;

export const SloganWrapper = styled.div``;
export const Slogan = styled.h1`
  text-transform: uppercase;
  color: var(--text-light-color);
  text-transform: uppercase;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 4rem;
  margin: 0;

  span {
    font-style: italic;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 2.5rem;
    line-height: 3rem;
    max-width: 20rem;
  }
  @media (min-width: ${(props) => props.theme.screen.md}) {
    font-size: 5.5rem;
    max-width: 45rem;
    line-height: 6rem;
    margin-bottom: 1.5rem;
  }
`;

export const SubTitleWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    max-width: 15rem;
  }
`;
export const SubTitle = styled.p`
  color: var(--marketing-secondary-text-color);
  font-size: 1.1rem;
  margin: 0;
  margin-bottom: 3rem;
  line-height: 1.3;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 1rem;
    margin: 0 0 1.5rem 0;
    font-weight: 400;
  }
  @media (min-width: ${(props) => props.theme.screen.md}) {
    font-size: 1.5rem;
    max-width: 25rem;
  }
`;

export const Button = styled.a`
  display: inline-block;
  border: 1px solid #ff871e;
  padding: 1rem 4rem;
  background: transparent;
  font-weight: 400;
  color: #ff871e;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    font-size: 1.5rem;
    padding: 1.5rem 5rem;
  }
`;
