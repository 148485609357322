import PropTypes from "prop-types";
import Container from "./styles";

const GradientBg = ({ children, minHeight }) => (
  <Container minHeight={minHeight}>{children}</Container>
);

GradientBg.defaultProps = {
  minHeight: "600",
};

GradientBg.propTypes = {
  children: PropTypes.node.isRequired,
  minHeight: PropTypes.string,
};

export default GradientBg;
