import CheckIcon from "@material-ui/icons/Check";
import { COMPETITORS, PRENEUR_FEATURES } from "../constants";
import { Section } from "../../../site-pages/MarketingPage/styles/global";

import {
  Heading,
  HeadingGroup,
  StyledCompetitorColumn,
  StyledCompetitorColumnFooter,
  StyledCompetitorItemContent,
  StyledCompetitorItemPrice,
  StyledCompetitorsGrid,
  StyledFeatureItem,
  StyledFeatureList,
  SubHeading,
} from "../styles";
import CompetitorItem from "./CompetitorItem";

const Competitors = () => (
  <Section id="competitors" accent="secondary">
    <HeadingGroup>
      <Heading>Unclutter & Save: Drop Other Tools!</Heading>
      <SubHeading>One platform replaces all your old tools.</SubHeading>
    </HeadingGroup>
    <StyledCompetitorsGrid>
      <StyledCompetitorColumn>
        {COMPETITORS.map((item) => (
          <CompetitorItem key={item.company} item={item} />
        ))}
        <StyledCompetitorColumnFooter>
          <StyledCompetitorItemContent>
            Buying all of these tools
          </StyledCompetitorItemContent>
          <StyledCompetitorItemPrice>$264/mo</StyledCompetitorItemPrice>
        </StyledCompetitorColumnFooter>
      </StyledCompetitorColumn>
      <StyledCompetitorColumn>
        <CompetitorItem
          item={{
            imageUrl:
              "https://res.cloudinary.com/scall/image/upload/v1694666288/sCallWeb/WebAssets/compIcons/Preneur_vqkra0.png",
            company: "Preneur",
            descriptions: "All-in-one platform",
            price: "Free to start",
          }}
        />
        <StyledFeatureList>
          {PRENEUR_FEATURES.map((feature) => (
            <StyledFeatureItem key={feature}>
              <CheckIcon />
              {feature}
            </StyledFeatureItem>
          ))}
        </StyledFeatureList>
        <StyledCompetitorColumnFooter>
          <StyledCompetitorItemContent>
            Do it all on Preneur for
          </StyledCompetitorItemContent>
          <StyledCompetitorItemPrice>$24.99</StyledCompetitorItemPrice>
        </StyledCompetitorColumnFooter>
      </StyledCompetitorColumn>
    </StyledCompetitorsGrid>
  </Section>
);

export default Competitors;
