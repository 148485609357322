const images = [
  {
    id: 1,
    url: "https://res.cloudinary.com/scall/image/upload/v1627677904/app_assets/hero_10_wuvrj7.png",
    type: "realestate",
  },
  {
    id: 2,
    url: "https://res.cloudinary.com/scall/image/upload/v1627677689/app_assets/hero_9_vvrlnb.png",
    type: "style",
  },
  {
    id: 3,
    url: "https://res.cloudinary.com/scall/image/upload/v1627669345/app_assets/hero_1_lwyahd.png",
    type: "finance",
  },
  {
    id: 4,
    url: "https://res.cloudinary.com/scall/image/upload/v1627669345/app_assets/hero_4_cxpxrg.png",
    type: "photo",
  },
  {
    id: 5,
    url: "https://res.cloudinary.com/scall/image/upload/v1627669343/app_assets/hero_2_umawwt.png",
    type: "chef",
  },
  {
    id: 6,
    url: "https://res.cloudinary.com/scall/image/upload/v1627676947/app_assets/hero_6_c67v8x.png",
    type: "fitness",
  },
  {
    id: 7,
    url: "https://res.cloudinary.com/scall/image/upload/v1627676948/app_assets/hero_7_nwx8ks.png",
    type: "coach",
  },
  {
    id: 8,
    url: "https://res.cloudinary.com/scall/image/upload/v1627677412/app_assets/hero_8_lyou3u.png",
    type: "service",
  },
];

export default images;
