import styled, { css } from "styled-components";

export const SectionCenter = styled.div`
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  margin: 0 auto;
  padding: 3rem 0;

  @media (max-width: 549px) {
    padding: 1rem 0;
  }
`;

export const CallToActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const TitleImg = styled.div`
  display: block;
  width: 100%;
  height: 460px;
  border-radius: 3px;
  background-image: url(${(props) =>
    props.swiperImg || "https://placehold.jp/450x675.png"});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 640px) {
    height: 500px;
  }
  @media (min-width: 960px) {
    height: 600px;
  }
`;

export const Title = styled.h2`
  color: var(--text-light-color);
  margin: 0;
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: 900;
  letter-spacing: 1px;
  ${(props) =>
    props.brand &&
    css`
      font-size: 3.2rem;
      margin-top: -1.5rem;
    `}
  @media (min-width: 550px) {
    font-size: 2.5rem;
    ${(props) =>
      props.brand &&
      css`
        font-size: 5.2rem;
        margin-top: -2rem;
      `}
  }
  @media (max-width: 549px) {
    font-size: 1.4rem;
    ${(props) =>
      props.brand &&
      css`
        font-size: 3rem;
        margin-top: -1rem;
        margin-bottom: 1.5rem;
      `}
  }
`;

export const SubTitle = styled.p`
  text-transform: capitalize;
  color: var(--text-light-color);
  margin: -1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.3;

  ${(props) =>
    props.margin &&
    css`
      margin-top: -2rem;
    `}
  ${(props) =>
    props.small &&
    css`
      font-size: 0.8rem;
    `}

  span {
    font-weight: 800;
    font-style: italic;
  }
  @media (min-width: 550px) {
    font-size: 1rem;
    margin-bottom: 3rem;
    ${(props) =>
      props.margin &&
      css`
        // margin: 1.5rem auto 1.5rem 1rem;
      `}
  }
  @media (max-width: 549px) {
    font-size: 0.8rem;
    margin-bottom: 2rem;
    line-height: 1rem;
    padding-bottom: 0.8rem;
    ${(props) =>
      props.margin &&
      css`
        // margin: 1.5rem auto 1.5rem 1rem;
      `}
  }
`;

export const SwiperWrapper = styled.div`
  padding: 0 1rem;
  width: 100%;

  @media (max-width: 640px) {
    padding: 0.5rem;
  }

  .swiper-pagination-bullet {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--text-secondary-color);
  }

  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--text-light-color);
  }
`;

export const HeaderWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  max-width: 750px;
`;

export const HeaderCopy = styled.h1`
  color: white;
  margin: 2rem 0;
  font-weight: 900;
  font-size: 4rem;
  line-height: 4rem;
`;

export const HeaderSubCopy = styled.p`
  color: white;
  font-weight: 600;
`;

export const UrlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--text-light-color);
  text-align: center;
  margin: 0 auto;
  min-width: 250px;
  max-width: 300px;
  height: 4rem;
  border-radius: 10px;
  padding: 1rem 0;
  color: var(--text-light-color);
  text-transform: lowercase;

  img {
    display: block;
    width: 1.5rem;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  span {
    font-weight: 800;
    color: var(--preneur-pink);
  }

  @media (min-width: 550px) {
    font-size: 1.5rem;
    min-width: 400px;
    max-width: 450px;
  }
`;

export const DashedDiv = styled.div`
  border-right: 1px dashed var(--text-light-color);
  height: 2rem;
  margin: 0 auto;
  width: 0;
`;

export const ImgWrapper = styled.div`
  width: 100%;

  img {
    display: block;
    object-fit: cover;
    padding-left: 5px;
    width: 100%;
  }
`;

export const SwiperName = styled.p`
  align-items: center;
  color: white;
  display: flex;
  font-size: 1.2rem;
  font-weight: 200;
  margin: 0;
  margin-top: 0.5rem;
  padding-top: 1rem;
  text-transform: uppercase;

  span {
    font-weight: 900;
    margin-left: 0.5rem;
  }
`;

export const CheckMark = styled.img`
  display: block;
  margin-left: 0.3rem;
  padding-bottom: 0.12rem;
  width: 1rem;
`;

export const SwiperPreneurType = styled.p`
  color: var(--text-light-color);
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0.2rem;

  span {
    color: white;
    font-family: var(--ff-preneur-type);
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: lowercase;
  }
`;

export const SwiperPhoneNumber = styled.p`
  align-items: center;
  color: white;
  display: flex;
  font-weight: 600;
  margin: 0 0 1rem;
`;

export const SwiperLogoWrapper = styled.div`
  margin-right: 0.4rem;

  img {
    display: block;
    object-fit: cover;
    width: 1.1rem;
  }
`;

export const SwiperButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--button-border-dark-color);
  border-radius: 5px;
  color: var(--text-light-color);
  font-size: 0.8rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
  width: fit-content;

  &: hover {
    border-color: var(--text-light-color);
    outline: none;
  }
`;
