import styled from "styled-components";
import { darken } from "polished";
import { Container } from "../../../site-pages/MarketingPage/styles/global";

export const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: ${(props) => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-between;

  padding: 3rem 0;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-flow: ${(props) => (props.reverse ? "row-reverse wrap" : "row wrap")};
    padding: 0;
  }
`;

export const ContentBlock = styled.div`
  max-width: 500px;
  padding: 1.5rem;
`;

export const Heading = styled.h3`
  margin: 0;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 2.5rem;

  color: ${(props) => props.theme.color.primary};
`;

export const IconWrapper = styled.span`
  font-size: 2rem;
  color: ${(props) => props.theme.color.brand};
`;

export const PreHeading = styled.h3`
  color: ${(props) => props.theme.color.brand};
  text-transform: uppercase;
  font-size: 0.8rem;

  line-height: 2rem;
  margin: 0;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  color: ${(props) => props.theme.color.black.light};
`;

export const ImageBlock = styled.div`
  max-width: 600px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const ReplacesBlock = styled.div`
  display: flex;
  flex-flow: column;
  span {
    color: ${(props) => props.theme.color.primary};
    font-weight: 600;

    margin: 0 0 0.5rem 0;
  }

  img {
    width: 80%;
    height: auto;
  }
`;

export const CTABlock = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0.9rem 0;

  span {
    font-size: 0.9rem;
    color: ${(props) => props.theme.color.black.light};
    font-weight: 600;
    margin: 0.8rem 0;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row;
`;

// TODO Please move me into my own component
export const CTAButton = styled.a`
  background-color: ${(props) =>
    props.clear ? "white" : props.theme.color.brand};
  color: ${(props) => (props.clear ? props.theme.color.brand : "white")};

  border: ${(props) =>
    props.clear ? `1px solid ${props.theme.color.brand}` : "none"};

  padding: 0.5rem 1.3rem;
  margin: 0 0.5rem 0 0;

  text-decoration: none;
  font-size: 0.9rem;
  border-radius: 2rem;

  width: fit-content;

  transition: 0.2s ease-in background-color;

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.color.brand)};
    color: white;
  }
`;
