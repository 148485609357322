import { useState } from "react";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
} from "swiper";
import {
  SlideWrapper,
  ContentContainer,
  TitleWrapper,
  Title,
  SloganWrapper,
  Slogan,
  SubTitleWrapper,
  SubTitle,
} from "./styles";

import images from "../../lib/microsite-images";
// Import Swiper styles
import "swiper/swiper-bundle.css";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);

const Gallery = () => {
  const [firstSwiper, setFirstSwiper] = useState(null);

  const slides = images.map((i) => (
    <SwiperSlide key={i.id}>
      <SlideWrapper bgImage={i.url}>
        <ContentContainer>
          <TitleWrapper>
            <Title>
              <span>{i.type}</span>
              .preneur
            </Title>
          </TitleWrapper>
          <SloganWrapper>
            <Slogan>you are the business</Slogan>
          </SloganWrapper>
          <SubTitleWrapper>
            <SubTitle>
              Finally, a powerful and easy to use platform, that enables a
              single person to run their entire business.
            </SubTitle>
          </SubTitleWrapper>
          {/* <Button>
            START FREE
          </Button> */}
        </ContentContainer>
      </SlideWrapper>
    </SwiperSlide>
  ));

  return (
    <Swiper
      id="main"
      loop
      loopFillGroupWithBlank
      autoplay={{
        delay: 4500,
        disableOnInteraction: true,
      }}
      initialSlide={firstSwiper || 0}
      spaceBetween={5}
      slidesPerView={1}
      onSwiper={setFirstSwiper}
      effect="fade"
    >
      {slides}
    </Swiper>
  );
};

export default Gallery;
