import { RiMessage2Fill } from "react-icons/ri";
import { AiFillSchedule } from "react-icons/ai";
import { FaStoreAlt } from "react-icons/fa";
import { HiChartSquareBar } from "react-icons/hi";

export const videoUrl = "https://youtu.be/69bZS7Cb4C4";
export const CTALink = "https://scall.link/join";
export const buttonLabel = "Request Access";

export const heroData = {
  heading: {
    __html: "Your entire business - one simple place",
  },
  subHeading: "For the on the go, mobile first preneur",
  buttonLabel,
  buttonURL: CTALink,
  smallHeading: "Request access & we’ll be in touch",
};

export const subHeroData = {
  heading: "YOU ARE THE BUSINESS",
  body: "Finally, a powerful and easy to use platform, that enables a single person to run their entire business on-the-go.",
};
export const personaData = {
  heading: "Your Solo Business, Powered from One Central Hub",
  body: "Earn effortlessly with Preneur: All you need to boost customers and income.\n" +
    " No clutter, just clarity.",
  buttonLabel,
  coverImage: "/assets/images/products/preneur-app-hero.png",
  buttonURL: CTALink,
  smallHeading: "Request access & we’ll be in touch",
};

export const featureHeroData = {
  heading: "Everything You Need to Connect, Grow and Sell.",
  subHeading: "Here's what you get with Preneur",
};

export const BandHeroData = {
  heading: "Revolutionize your networking game: one tap, endless connections.",
  subHeading: "Tap Into You. Connect, Share, Amplify.",
  coverImage: "/assets/images/products/wrist_band.png",
  coverVideo: "https://res.cloudinary.com/scall/video/upload/v1693289560/sCallWeb/WebAssets/Tap_Flex_and_Connect_-_Showcase_the_Full_You_j0l7we.mp4",
  coverImageAlt: "Preneur Wrist Band",
};

export const featureItemsData = [
  // {
  //   position: 0,
  //   icon: <RiMessage2Fill />,
  //   preTitle: "Digital Card",
  //   title: "Tap to Share, Connect Instantly, Amplify Connections",
  //   body: "Preneur's modern networking gear seamlessly bridges tactile traditions with digital advantages. Elevate in-person exchanges to a new, contactless dimension. It's the essence of a classic business card, reimagined for today's digital landscape.",
  //   coverImage: "/assets/images/products/preneur_tap.png",
  //   coverImageAlt: "Preneur Product - Private SMS line",
  //   replacesImageAlt: "Preneur Replace TextMessage Apps",
  //   CTAStartUrl: CTALink,
  //   CTALeanMoreUrl: null,
  //   videoUrl,
  // },
  {
    position: 0,
    icon: <RiMessage2Fill />,
    preTitle: "Digital Card",
    title: "Tap to Share, Connect Instantly, Amplify Connections",
    body: "Preneur's modern networking gear seamlessly bridges tactile traditions with digital advantages. Elevate in-person exchanges to a new, contactless dimension. It's the essence of a classic business card, reimagined for today's digital landscape.",
    coverImage: "/assets/images/products/preneur_tap.png",
    coverImageAlt: "Preneur Product - Private SMS line",
    replacesImageAlt: "Preneur Replace TextMessage Apps",
    CTAStartUrl: CTALink,
    CTALeanMoreUrl: null,
    videoUrl,
  },
  {
    position: 1,
    icon: <RiMessage2Fill />,
    preTitle: "Preneur Microsite",
    title: "Simple by Design, Crafted for Human Connection - Your Web Presence, Amplified",
    body: "Embrace the essence of simplicity, and let your online presence speak volumes. With our platform, you're not just building a site; you're forging genuine human connections. Amplify your message and resonate with those who matter most, all while keeping things effortlessly streamlined.",
    coverImage: "/assets/images/products/connect_human.png",
    coverImageAlt: "Preneur Product - Private SMS line",
    replacesImage: "/assets/images/replaces/replaces-text.png",
    replacesImageAlt: "Preneur Replace TextMessage Apps",
    CTAStartUrl: CTALink,
    CTALeanMoreUrl: null,
    videoUrl,
  },
  {
    position: 2,
    icon: <AiFillSchedule />,
    preTitle: "Schedule & Book Appointments",
    title: "Quick Scheduling, No Hassles. Optimize Time, Boost Earnings.",
    body: "Schedule appointments faster without the timely back and forth.  And maximize your time to make more.",
    coverImage: "/assets/images/products/bookme.png",
    coverImageAlt: "Preneur Product - Schedule & Book Appointments",
    replacesImage: "/assets/images/replaces/replaces-book.png",
    replacesImageAlt: "Preneur Replace Booking Apps",
    CTAStartUrl: null,
    CTALeanMoreUrl: "/features/book-time",
    videoUrl: "https://youtu.be/89942Q4KNKY",
  },
  {
    position: 3,
    icon: <FaStoreAlt />,
    preTitle: "Sell Products & Services",
    title: "Offer Products, Amplify Sales.",
    body: "Quickly set up and showcase both digital and physical goods, ensuring seamless transactions and amplified sales.",
    coverImage: "/assets/images/products/sell.png",
    coverImageAlt: "Preneur Product - Sell Products & Services",
    replacesImage: "/assets/images/replaces/replaces-buysell.png",
    replacesImageAlt: "Preneur Replace Shopify",
    CTAStartUrl: CTALink,
    CTALeanMoreUrl: null,
    videoUrl,
  },
  {
    position: 4,
    icon: <HiChartSquareBar />,
    preTitle: "Analytics that Make Sense",
    title: "Engage, Analyze, Thrive.",
    body: "Dive deep into audience insights, track evolving engagement, and pinpoint revenue drivers. Stay agile, make smart tweaks, and ensure your audience stays hooked.",
    coverImage: "/assets/images/products/Scall-data.png",
    coverImageAlt: "Preneur Product - Analytics that Make Sense",
    replacesImage: "/assets/images/replaces/replaces-data.png",
    replacesImageAlt: "Preneur Replace Samcart",
    CTAStartUrl: CTALink,
    CTALeanMoreUrl: null,
    videoUrl,
  },
];

export const CTA = {
  heading: "Preneur, a better way to grow.",
  subHeading:
    "With the tools to automate and help your customer engagements feel more authentic. Sign up today and start growing!",
  buttonLabel,
  buttonURL: CTALink,
};
