import PropTypes from "prop-types";
import {
  StyledCompetitorItem,
  StyledCompetitorItemContent,
  StyledCompetitorItemImage,
  StyledCompetitorItemPrice,
  StyledCompetitorItemText,
  StyledCompetitorItemTitle,
} from "../styles";

const CompetitorItem = ({ item }) => {
  return (
    <StyledCompetitorItem key={item.company}>
      <StyledCompetitorItemImage>
        <img src={item.imageUrl} alt={item.company} />
      </StyledCompetitorItemImage>
      <StyledCompetitorItemContent>
        <StyledCompetitorItemTitle>{item.company}</StyledCompetitorItemTitle>
        <StyledCompetitorItemText>{item.descriptions}</StyledCompetitorItemText>
      </StyledCompetitorItemContent>
      <StyledCompetitorItemPrice>{item.price}</StyledCompetitorItemPrice>
    </StyledCompetitorItem>
  );
};

CompetitorItem.propTypes = {
  item: PropTypes.shape({
    company: PropTypes.string,
    descriptions: PropTypes.string,
    price: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
};

export default CompetitorItem;
