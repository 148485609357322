import Navigation from "../../components/common/navigation/navigation";
import LandingCover from "../../components/modules/MarketingPage/CTASection";
import HeroSection from "../../components/HeroSection";
import PersonalBrand from '../../components/sections/PersonalBrand';
import Features from "../../components/sections/features";
import BandShowcase from "../../components/sections/BandShowcase";
import Footer from "../../components/sections/footer";
import PageHead from "../../components/PageHead";
import { BodyWrapper } from "./styles/global";
import SliderSection from "../../components/modules/MarketingPage/SliderSection";
import Competitors from "../../components/sections/Competitors/competitors";

const MarketingPage = () => (
  <>
    <PageHead />
    <Navigation />
    <BodyWrapper>
      <LandingCover />
      <PersonalBrand />
      <BandShowcase />
      <Features />
      <SliderSection />
      <Competitors />
      <HeroSection />
      <Footer />
    </BodyWrapper>
  </>
);

export default MarketingPage;
