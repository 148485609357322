import ReactPlayer from "react-player";
import { useCallback, useState } from "react";
import AppDownload from "../../../AppDownload";
import { Title, SubTitle, CallToActionWrapper } from "../styles";
import {
  CtaSectionWrapper,
  StyledIconPlay,
  StyledImage,
  StyledImageWrapper,
} from "./styles";
import { ReactPlayerWrapper } from "../../../ScallVideo/styles";

const CTASection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const startPlaying = useCallback(() => {
    setIsPlaying(true);
  }, []);
  const stopPlaying = useCallback(() => {
    setIsPlaying(false);
  }, []);

  return (
    <CtaSectionWrapper>
      <StyledImageWrapper onClick={isPlaying ? stopPlaying : startPlaying}>
        {!isPlaying && (
          <>
            <StyledIconPlay />
            <StyledImage src="/assets/images/marketing/Cover_Image@2x.png" />
          </>
        )}
        {isPlaying && (
          <ReactPlayerWrapper>
            <ReactPlayer
              url="/assets/video/preneur_cover.mp4"
              playsinline
              playing={isPlaying}
              width="100%"
              height="100%"
              onEnded={stopPlaying}
            />
          </ReactPlayerWrapper>
        )}
      </StyledImageWrapper>

      <CallToActionWrapper>
        <Title>EMPOWERING THE MODERN-DAY</Title>
        <Title brand>
          ENTRE<em style={{ color: "#ff27a2" }}>PRENEUR</em>
        </Title>
        <SubTitle small>
          Preneur is the all-in-one platform that puts the power
          <br />
          of success in your hands.
        </SubTitle>
        <AppDownload />
      </CallToActionWrapper>
    </CtaSectionWrapper>
  );
};

export default CTASection;
