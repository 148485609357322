import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import { Pagination } from "swiper";

import {
  SwiperWrapper,
  SwiperName,
  SwiperPreneurType,
  SwiperPhoneNumber,
  TitleImg,
  SwiperLogoWrapper,
  CheckMark,
  SwiperButton,
  HeaderWrapper,
  HeaderCopy,
} from "../styles";

import GradientBg from "../../../elements/GradientBg";
import headerData from "./headerData";

const SliderSection = () => (
  <GradientBg>
    <SwiperWrapper>
      <HeaderWrapper>
        <HeaderCopy>
          Your In Good Company Dive into the Preneur Community
        </HeaderCopy>
      </HeaderWrapper>
      <Swiper
        spaceBetween={10}
        slidesPerView={1.5}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },

          1200: {
            slidesPerView: 3.5,
            spaceBetween: 10,
          },
          1800: {
            slidesPerView: 4.5,
            spaceBetween: 10,
          },
        }}
        scrollbar={{ draggable: true }}
        className="mySwiper"
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {headerData.map((data) => {
          const {
            id,
            imgUrl,
            firstName,
            lastName,
            preneurType,
            phone,
            userHandle,
          } = data;
          return (
            <SwiperSlide
              key={id}
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "2rem",
              }}
            >
              <TitleImg swiperImg={imgUrl} />

              <SwiperName>
                {firstName}
                <span> {lastName}</span>

                <CheckMark
                  src="./images/verifiedCheckIcon.png"
                  alt="checkmark"
                />
              </SwiperName>
              <SwiperPreneurType>
                <span>{preneurType}</span>.PRENEUR
              </SwiperPreneurType>
              <SwiperPhoneNumber>
                <SwiperLogoWrapper>
                  <img
                    src="https://res.cloudinary.com/scall/image/upload/v1620749904/app_assets/P-icon-white_gbeuuy.png"
                    alt="preneur logo"
                  />
                </SwiperLogoWrapper>
                {phone}
              </SwiperPhoneNumber>
              <Link href={`https://preneur.ai/${userHandle}`}>
                <SwiperButton>connect</SwiperButton>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperWrapper>
  </GradientBg>
);

export default SliderSection;
