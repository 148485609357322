const headerData = [
  {
    id: 1,
    userHandle: "brandiharvey",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680549302/sCallWeb/WebAssets/Brandi_qz8hed.png",
    firstName: "BRANDI",
    lastName: "HARVEY",
    preneurType: "wellness",
    phone: "(404) 998-4080",
  },
  {
    id: 344,
    userHandle: "lotusBelly",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1693185086/sCallWeb/WebAssets/SK_zugqr4.png",
    firstName: "STACY",
    lastName: "BURK",
    preneurType: "wellness",
    phone: "(404) 777-5421",
  },
  {
    id: 3334,
    userHandle: "Coachbrinichols",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1693185929/sCallWeb/WebAssets/Coachbrinichols_aonpit.png",
    firstName: "Coach bri",
    lastName: "nichols",
    preneurType: "coach",
    phone: "(508) 625-7333",
  },
  {
    id: 6,
    userHandle: "jasmincadavid",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680586318/sCallWeb/WebAssets/jasmincadavid_248356153_206698241538417_9201088535953528134_n_2x_d2rnam.png",
    firstName: "jasmin",
    lastName: "cadavid",
    preneurType: "artist",
    phone: "(305) 614-6213",
  },
  {
    id: 5,
    userHandle: "TheLuxRealtor",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680586340/sCallWeb/WebAssets/Screen_Shot_2023-04-04_at_1.10.01_AM_2x_j10pqn.png",
    firstName: "CRISTINA",
    lastName: "FERREIRA",
    preneurType: "realestate",
    phone: "(470) 999-7741",
  },
  {
    id: 9,
    userHandle: "amadeu",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680590597/sCallWeb/WebAssets/Amadeu_b0b5cd.png",
    firstName: "Amadeu",
    lastName: "Tolentino",
    preneurType: "tech",
    phone: "(617) 440-4030",
  },
  {
    id: 4,
    userHandle: "claudiajordan",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680590560/sCallWeb/WebAssets/claudiajordan_qav368.png",
    firstName: "claudia",
    lastName: "jordan",
    preneurType: "media",
    phone: "(214) 466-7442",
  },
  {
    id: 3,
    userHandle: "brianwhite",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680586317/sCallWeb/WebAssets/brianjwhite_242732798_354798089720614_4601609029489279506_n_2x_qpahjt.png",
    firstName: "brian",
    lastName: "white",
    preneurType: "impact",
    phone: "(424) 352-8505",
  },
  {
    id: 7,
    userHandle: "rocsiglobal",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680586317/sCallWeb/WebAssets/rocsi.global_277691459_5034825503220467_1435931363016785490_n_2x_ejohgn.png",
    firstName: "ROCSI",
    lastName: "GLOBAL",
    preneurType: "realestate",
    phone: "(404) 999-3635",
  },
  {
    id: 8,
    userHandle: "AA_twinss",
    imgUrl:
      "https://res.cloudinary.com/scall/image/upload/v1680590504/sCallWeb/WebAssets/AA_twinss_x3rfmz.png",
    firstName: "AA",
    lastName: "TWINS",
    preneurType: "brand",
    phone: "(857) 392-3311",
  },
];

export default headerData;
