/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import {
  ContentContainer,
  HeadingWrapper,
  ContentWrapper,
  Heading,
  SubHeading,
  ImageBlock,
  VideoBlock,
} from "./styles";

import { BandHeroData } from "../../../site-pages/MarketingPage/content/landingPageData";

const { heading, subHeading, coverImage, coverImageAlt, coverVideo } = BandHeroData;

const BandShowcase = () => (
  <ContentContainer>
    <HeadingWrapper>
      <Heading>{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
    </HeadingWrapper>
    <ContentWrapper>
      <ImageBlock>
        <img src={coverImage} alt={coverImageAlt} />
      </ImageBlock>
      <VideoBlock>
        <video preload="true" autoPlay muted loop playsInline>
          <source src={coverVideo} type="video/mp4" />
        </video>
      </VideoBlock>
    </ContentWrapper>
  </ContentContainer>
);

export default BandShowcase;
