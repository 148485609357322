import {
  ContentWrapper,
  ContentBlock,
  ImageBlock,
  Heading,
  SubHeading,
  PreneurImg,
  MicroFeatureListWrapper,
  MicroFeatureListItem,
  MicroFeatureListTitle,
  MicroFeatureListImageWrapper,
} from "./styles";

import { personaData } from "../../../site-pages/MarketingPage/content/landingPageData";

const { heading, body, coverImage } = personaData;

const PersonalBrand = () => (
  <ContentWrapper>
    <ImageBlock>
      <PreneurImg src={coverImage} />
    </ImageBlock>
    <ContentBlock>
      <Heading>{heading}</Heading>
      <SubHeading>{body}</SubHeading>
    </ContentBlock>
    <MicroFeatureListWrapper>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/vbc.png" />
        <MicroFeatureListTitle>Digital Card</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/send_SMS.png" />
        <MicroFeatureListTitle>Send Bulk Texts</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/book_time.png" />
        <MicroFeatureListTitle>Book, Get Paid</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/tickets.png" />
        <MicroFeatureListTitle>Tickets & Events</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/sell.png" />
        <MicroFeatureListTitle>Sell Anything</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/contacts.png" />
        <MicroFeatureListTitle>Manage Contacts</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/results.png" />
        <MicroFeatureListTitle>Measure results</MicroFeatureListTitle>
      </MicroFeatureListItem>
      <MicroFeatureListItem>
        <MicroFeatureListImageWrapper src="/assets/images/feature_images/list.png" />
        <MicroFeatureListTitle>Grow your list</MicroFeatureListTitle>
      </MicroFeatureListItem>
    </MicroFeatureListWrapper>
  </ContentWrapper>
);

export default PersonalBrand;
