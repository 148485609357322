/* eslint-disable react/prop-types */
import {
  StyledContainer,
  ContentBlock,
  ImageBlock,
  Heading,
  PreHeading,
  Text,
  CTABlock,
} from "./styles";

const FeatureBlock = ({
  position,
  preTitle,
  title,
  body,
  coverImage,
  coverImageAlt,
}) => {
  const isReverse = () => position % 2 === 0;

  return (
    <StyledContainer reverse={isReverse()}>
      <ContentBlock>
        <PreHeading>{preTitle}</PreHeading>
        <Heading>{title}</Heading>
        <Text>{body}</Text>
        <CTABlock>
          <span>Get started FREE - Upgrade as you grow</span>
          {/* <ButtonGroup>
            {CTAStartUrl && (
              <CTAButton target="_blank" href={CTAStartUrl}>
                Request Access
              </CTAButton>
            )}

            {CTALeanMoreUrl && (
              <CTAButton clear href={CTALeanMoreUrl}>
                Learn more
              </CTAButton>
            )}
            {videoUrl && <ScallVideo clear={false} videoUrl={videoUrl} />}
          </ButtonGroup> */}
        </CTABlock>
      </ContentBlock>
      <ImageBlock>
        <img src={coverImage} alt={coverImageAlt} />
      </ImageBlock>
    </StyledContainer>
  );
};

export default FeatureBlock;
